<template>
  <div class="home">
    <br />
    <br />
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isLoading" class="text-center">
          データ読み込み中です。
          <img src="../assets/loader.gif" />
        </div>
        <div v-else>
          <div class="form-row justify-content-center" v-if="!isQuery">
            <div class="form-group">
              <label for="targetDate">&nbsp;&nbsp;&nbsp;&nbsp;納入日：</label>
              <button
                class="btn btn-outline-secondary dropdown-toggle"
                type="button"
                id="targetDate"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ selectedDispachDateDisp }}
              </button>
              <div class="dropdown-menu scrollable-menu">
                <a
                  class="dropdown-item"
                  v-for="(dateSet, index) in selectedDispachDateSet"
                  :key="index"
                  @click="changeSelectedDispachDate(index)"
                  >{{ dateSet.disp }}</a
                >
              </div>
            </div>
          </div>
          <div class="form-row justify-content-center in-line">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="radioC"
                id="radioC"
                v-model="type"
                value="container"
                @change="changeType($event.target.value)"
              />
              <label class="form-check-label" for="radioC">ｺﾝﾃﾅ</label>
            </div>
            &nbsp;&nbsp;
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="radioT"
                id="radioT"
                v-model="type"
                value="truck"
                @change="changeType($event.target.value)"
              />
              <label class="form-check-label" for="radioT">ﾄﾗｯｸ</label>
            </div>
          </div>
          <form @submit.prevent="getData(false)">
            <div class="form-row form-inline">
              <div class="form-group">
                <label for="containerNo">{{ dispType }}：</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="no"
                    size="16"
                    placeholder=""
                    v-model="no"
                  />
                </div>
              </div>
            </div>
            <br>
            <br>
            <div class="form-row justify-content-center">
              <button type="button" class="btn btn-primary" @click="getData(false)">
                検索<i class="fas fa-search"></i>
              </button>
            </div>
            <br />
          </form>
        </div>
      </div>
      <div v-if="no == ''">検索条件を入力してください。<br /></div>
      <div v-else-if="isInput">
        <div v-if="dataSet.length === 0">該当のデータはありません。<br /></div>
        <div v-else>
          <div v-if="someData">
            <div class="row justify-content-center">
              選択した日付には同じコンテナNo.で複数履歴があります。<br>
              下記より伝票番号を選択してください。
            </div>
            <div class="justify-content-center">
                <div v-for="(datas, index) in dataSet" :key="index">
                  No.<a href="javascript:void(0)"
                    @click="onChangeselectedDispatchNo(datas.dispatchNo)"
                    >{{ datas.dispatchNo }}</a
                  ><br>
                </div>
            </div>
          </div>
          <div v-if="data != ''">
            <div class="row">
              <div class="col-12">
                <div class="text-center"><b>追跡結果</b><br /></div>
              </div>
            </div>
            <div class="container">
              <div class="col-12">
                <hr />
                <div class="text-center" v-if="toName != ''">{{ toName }}</div>
                <div v-if="dispDriver(driverName)" class="text-center">ﾄﾞﾗｲﾊﾞｰ：{{ driverName }}<br></div>
                <div v-if="dispDriver(driverNo)" class="text-center">車番：{{ driverNo }}<br></div>
                <div v-if="dispDriver(driverTel)" class="text-center">TEL：{{ driverTel }}<br></div>
                <br />
                <div class="text-center">納入日：{{ convertDateDisp(data.dispatchDate) }}<br></div>
                <div class="text-center" v-if="type == 'container'">ｺﾝﾃﾅ№ ：{{ no }}<br /><br /></div>
                <div class="text-center" v-if="type == 'truck'">ｵｰﾀﾞｰ№ ：{{ no }}<br /><br />
                </div>
              </div>
              <div class="col-12" v-if="data.pickupDate != ''">
                <div class="text-center">PickUp:{{ data.pickupDate }}<br></div>
              </div>
              <div class="row justify-content-center"  v-if="data.pickupDate != ''">
                <div class="col-1">
                  <i class="fas fa-arrow-down blink"></i>
                </div>
              </div>
              <div class="col-12" v-if="data.departureDate != ''">
                <div class="text-center">出発:{{ data.departureDate }}<br></div>
              </div>
              <div class="col-12" v-else>
                <div class="text-center">まだ出発していません。<br></div>
              </div>
              <div class="row justify-content-center">
                <div class="col-1">
                  <i class="fas fa-arrow-down blink"></i>
                </div>
              </div>
              <div class="col-12" v-if="data.arrivalDate != ''">
                <div class="text-center">到着:{{ data.arrivalDate }}<br></div>
              </div>
              <div class="col-12" v-else>
                <div class="text-center">まだ到着していません。<br></div>
              </div>
              <div class="row justify-content-center">
                <div class="col-1">
                  <i class="fas fa-arrow-down blink"></i>
                </div>
              </div>
              <div class="col-12" v-if="data.complationDate != ''">
                <div class="text-center">完了:{{ data.complationDate }}<br></div>
              </div>
              <div class="col-12" v-else>
                <div class="text-center">まだ完了していません。<br></div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from "../modules/http";

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      isLoading: false,
      isInput: false,
      isQuery: false,
      selectedDispachDate: null,
      selectedDispachDateDisp: "",
      selectedDispachDateSet: [{ disp: "", value: "" }],
      selectedDispachNo:"選択してください。",
      dataSet: [],
      data: {
        container: "",
        truck: "",
        dispatchDate: "",
        pickupDate: "",
        departureDate: "",
        arrivalDate: "",
        complationDate: "",
        memo: "",
      },
      driverName: "",
      driverTel: "",
      driverNo: "",
      no: "",
      containerNo: "",
      truckNo: "",
      dispachNo:"",
      targetDate: "",
      targetDateDisp: "",
      toName: "",
      httpClient: new HttpClient(),
      type: "container",
      dispType: "ｺﾝﾃﾅ№",
      dispTypes: { container: "ｺﾝﾃﾅ№", truck: "ｵｰﾀﾞｰ№" },
      someData: false
    };
  },
  created: function () {
    this.makeDropdownDispachDate();
    if (this.$route.query != null && this.$route.query.qr != null) {
      this.isQuery = true;
      this.isInput = true;
      let qr = this.$route.query.qr;
      let qr_array = qr.split("@");
      qr = qr_array[0];
      if(qr_array.length > 1){
        this.dispachNo = qr_array[1]
      }
      if (qr.length >= 19) {
        this.selectedDispachDate = qr.slice(0, 8);
        if (qr.slice(8, 9) == "-") {
          this.type = "truck";
          this.no = qr.slice(9, qr.length);
          this.truckNo = qr.slice(9, qr.length);
          this.getTruckData(true);
        } else {
          this.type = "container";
          this.no = qr.slice(8, 19);
          this.containerNo = qr.slice(8, 19);
          this.getContainerData(true);
        }
      }
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    makeDropdownDispachDate() {
      let nowDate = new Date();

      let values =
        String(nowDate.getFullYear()) +
        String(("0" + (nowDate.getMonth() + 1)).slice(-2)) +
        String(("0" + nowDate.getDate()).slice(-2));
      let dispData = this.convertDateDisp(values);

      this.selectedDispachDate = values;
      this.selectedDispachDateDisp = dispData;

      for (let i = 7; -14 <= i; i--) {
        nowDate = new Date();
        nowDate.setDate(nowDate.getDate() + i);
        values =
          String(nowDate.getFullYear()) +
          String(("0" + (nowDate.getMonth() + 1)).slice(-2)) +
          String(("0" + nowDate.getDate()).slice(-2));
        dispData = this.convertDateDisp(values);

        let dateSet = {
          disp: dispData,
          value: values,
        };
        this.selectedDispachDateSet.push(dateSet);
      }
    },
    dispDriver(value){
      let ret = false;
      if(value != ""){
        if(this.data != null) {
          if(this.data.departureDate == "" && this.data.arrivalDate == "" && this.data.complationDate == ""){
            ret = false;
          }else{
            ret = true;
          }
        }
      }
      console.log(this.data);
      console.log(this.data.departureDate);
      console.log(this.data.arrivalDate);
      console.log(this.data.complationDate);
      console.log(ret);
      return ret;
    },
    changeType(value) {
      this.type = value;
      this.dispType = this.dispTypes[value];
      this.dataSet = [];
      this.getData(false);
      this.$forceUpdate();
    },
    changeSelectedDispachDate(index) {
      this.isInput = false;
      this.dataSet = [];
      this.selectedDispachDate = this.selectedDispachDateSet[index].value;
      this.selectedDispachDateDisp = this.selectedDispachDateSet[index].disp;
      // if(this.type == "container"){
      //   this.getContainerData(false);
      // }else{
      //   this.getTruckData(false);
      // }
      // this.$forceUpdate();
    },
    getData(isQuery) {
      if (this.type == "container") {
        this.containerNo = this.no;
        this.getContainerData(isQuery);
      } else {
        this.truckNo = this.no;
        this.getTruckData(isQuery);
      }
    },
    getContainerData(isQuery) {
      this.dataSet = [];
      this.type = "container";
      this.isQuery = isQuery;
      if (isQuery == false) {
        this.toName = "";
      }
      this.isLoading = true;
      this.isInput = true;
      const url = "app.qrqr.work/containerTrace/ctm.php?con=" + this.containerNo + "&dispach_no=" + this.dispachNo;
      this.httpClient.get(url, this.onSuccess, this.onError);
    },
    getTruckData(isQuery) {
      this.dataSet = [];
      this.type = "truck";
      this.isQuery = isQuery;
      if (isQuery == false) {
        this.toName = "";
      }
      this.isLoading = true;
      this.isInput = true;
      const url = "file.d2dship.com/truckTrace/ctm/?tr=" + this.truckNo;
      this.httpClient.get(url, this.onSuccess, this.onError);
    },
    onSuccess(response) {
      this.isLoading = false;
      let res_buff = JSON.stringify(response.data);
      res_buff = JSON.parse(res_buff);
      if("traceList" in res_buff){
        this.dataSet = res_buff.traceList;
      }
      if (this.dataSet.length > 0) {
        if (this.selectedDispachDate != null) {
          const selectData = this.selectedDate(this.selectedDispachDate);
          if (selectData) {
            if(selectData.length > 1){
              this.data = ""
              this.someData = true;
              console.log(this.dataSet)
            }else{
              this.data = selectData[0];
              this.someData = false;
              console.log(this.dataSet)
            }
          }
        } else {
          this.data = this.dataSet[0];
        }
        if (this.data != "" && this.data.memo != "") {
          const driverInfo = this.data.memo.split("@@");
          this.driverNo = driverInfo[1];
          this.driverName = driverInfo[2];
          this.driverTel = driverInfo[3];
        } else {
          this.driverNo = "";
          this.driverName = "";
          this.driverTel = "";
        }
      }
      this.$forceUpdate();
    },
    onError() {
      this.isLoading = false;
    },
    convertDateDisp(date) {
      if (date == null) {
        return "";
      }
      return date.slice(0, 4) + "年" + date.slice(4, 6) + "月" + date.slice(6, 8) + "日";
    },
    selectedDate(value) {
      this.selectedDispachDate = value;
      this.dataSet = this.dataSet.filter((list) => {
        if (list.dispatchDate == this.selectedDispachDate) {
          return true;
        } else {
          return false;
        }
      });
      if (this.dataSet.length == 0) {
        return false;
      }
      return this.dataSet;
    },
    onChangeselectedDispatchNo(value) {
      this.selectedDispachNo = value;
      for(let idx in this.dataSet){
        console.log(this.dataSet[idx].dispatchNo)
        console.log(value)
        if(this.dataSet[idx].dispatchNo == value){
          this.data = this.dataSet[idx]  
          if (this.data != "" && this.data.memo != "") {
            const driverInfo = this.data.memo.split("@@");
            this.driverNo = driverInfo[1];
            this.driverName = driverInfo[2];
            this.driverTel = driverInfo[3];
          } else {
            this.driverNo = "";
            this.driverName = "";
            this.driverTel = "";
          }
        }
      }
    },
  },
};
</script>
